.contacts {
  position: relative;
  border-radius: 32px;
  border: 1px solid #373737;
  margin-bottom: 80px;
  overflow: hidden;
  text-align: center;
  padding: 80px;

  @media screen and (max-width: 1152px) {
    margin-bottom: 64px;
    padding: 64px 0 56px 0;
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 40px;
    padding: 64px 20px 40px 20px;
  }
  @media screen and (max-width: 475px) {
    padding: 32px 16px 0 16px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  img {
    position: absolute;
    z-index: -2;
    left: -10px;
    right: -10px;
    bottom: -10px;
    top: -10px;
    width: 105%;
    height: 105%;
    margin: auto;
  }

  &__title {
    font-weight: 700;
    font-size: 52px;
    line-height: 64px;
    padding-bottom: 24px;

    @media screen and (max-width: 1152px) {
      font-weight: 600;
      font-size: 36px;
      line-height: 52px;
    }

    @media screen and (max-width: 475px) {
      font-size: 26px;
      line-height: 32px;
    }
  }

  &__description {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    padding-bottom: 80px;
    max-width: 800px;
    margin: 0 auto;

    @media screen and (max-width: 1152px) {
      font-size: 18px;
      line-height: 24px;
      padding-bottom: 24px;
      max-width: 582px;
    }

    @media screen and (max-width: 475px) {
      font-size: 15px;
      line-height: 20px;
      padding-bottom: 0;
    }
  }

  &__sections {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 100%;
  }

  &__section {
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    text-align: center;

    margin: 16px 8px;

    @media screen and (max-width: 1152px) {
      font-size: 18px;
      line-height: 24px;
    }
    @media screen and (max-width: 768px) {
      font-size: 18px;
      margin: 24px 8px;
    }
    @media screen and (max-width: 475px) {
      font-size: 15px;
      margin: 24px 0;
      width: 100%;

      &:last-child {
        margin-top: -8px;
      }
    }

    &__buttons {
      display: flex;
      justify-content: space-between;
      margin-top: 32px;

      @media screen and (max-width: 1152px) {
        margin-top: 28px;
      }
      @media screen and (max-width: 475px) {
        margin-top: 8px;
        flex-direction: column;
      }
    }

    &__social-button,
    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      height: 54px;

      font-weight: 700;
      font-size: 14px;

      background: #101010;
      border: 1px solid #FFFFFF;
      border-radius: 40px;

      transition: all 250ms;

      &--github {
        font-size: 18px;
      }

      svg {
        margin-right: 8px;
      }

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        border: 1px solid #101010;
      }
    }

    &__social-button {
      width: 155px;
      margin-right: 16px;

      &--tg, &--tw, &--discord {
        font-size: 18px;
        border: none;

        &:hover {
          opacity: 0.8;
          border: none !important;
        }
      }

      &--tg {
        background: #229ED9;
      }

      &--discord {
        background: #5865F2;
      }

      &--tw {
        background: #1DA1F2;
      }

      @media screen and (max-width: 1152px) {
        width: 135px;
        height: 48px;
        margin-right: 12px;

        &--tg, &--tw, &--discord {
          font-size: 14px;
        }
      }

      @media screen and (max-width: 475px) {
        width: 100%;
        margin: 8px 0;

        &--tg, &--tw, &--discord {
          font-size: 18px;
        }
      }
    }

    &__button {
      width: 198px;
      margin-right: 24px;

      @media screen and (max-width: 1152px) {
        width: 180px;
        height: 48px;
        margin-right: 16px;
      }

      @media screen and (max-width: 475px) {
        width: 100%;
        margin: 8px 0;
      }
    }
  }
}