.footer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 40px 0;
  user-select: none;
  color: #828282;

  @media screen and (max-width: 768px) {
    padding-bottom: 70px;
  }

  @media screen and (max-width: 475px) {
    flex-direction: column;
    padding-bottom: 30px;
  }

  &__right,
  &__left,
  &__center {
    width: 285px;

    @media screen and (max-width: 1152px) {
      width: 245px;
    }

    @media screen and (max-width: 768px) {
      width: max-content;
    }
  }

  &__left {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 22px;
    line-height: 32px;
    color: white;

    @media screen and (max-width: 1152px) {
      font-size: 18px;
      line-height: 32px;
    }

    @media screen and (max-width: 475px) {
      font-size: 16px;
      line-height: 21px;
    }

    img {
      margin-right: 16px;

      width: 56px;

      @media screen and (max-width: 1152px) {
        margin-right: 12px;
        width: 48px;
      }
      @media screen and (max-width: 475px) {
        width: 40px;
      }
    }
  }

  &__center {
    position: absolute;
    bottom: 52px;
    left: 50%;
    transform: translate(-50%);

    @media screen and (max-width: 768px) {
      position: static;
      transform: none;
    }

    @media screen and (max-width: 475px) {
      padding: 16px 0 8px 0;
    }
  }

  &__right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__link, &__center {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    @media screen and (max-width: 1152px) {
      font-size: 14px;
      line-height: 24px;
    }
  }

  &__link {
    padding: 5px;
    margin-right: 26px;
    color: #828282;
    text-decoration: none;

    @media screen and (max-width: 1152px) {
      margin-right: 14px;
    }

    @media screen and (max-width: 768px) {
      margin-right: 6px;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}