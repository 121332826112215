body {
  margin: 0;
  font-family: 'DM Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: #101010;
  color: white;
}

.text-gradient {
  background: linear-gradient(74.43deg, #D8ADFB -12.43%, #B8D3F6 24.51%, #A7F5D5 63.46%, #F3EFC0 82.01%, #F3B6F4 110.47%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.title {
  font-weight: 700;
  font-size: 44px;
  line-height: 58px;
  color: white;
  text-align: center;
  padding-bottom: 64px;

  @media screen and (max-width: 1152px) {
    font-weight: 600;
    font-size: 36px;
    line-height: 52px;
    padding-bottom: 40px;
  }

  @media screen and (max-width: 768px) {
    font-size: 32px;
    line-height: 46px;
    padding-bottom: 32px;
  }

  @media screen and (max-width: 475px) {
    font-size: 26px;
    line-height: 32px;
    padding-bottom: 24px;
  }
}

.container {
  position: relative;
  max-width: 1220px;
  width: calc(100% - 80px);
  margin: 0 auto;
  box-sizing: border-box;

  @media screen and (max-width: 1152px) {
    margin: 0 88px;
    width: calc(100% - 176px);
  }

  @media screen and (max-width: 768px) {
    margin: 0 40px;
    width: calc(100% - 80px);
  }

  @media screen and (max-width: 475px) {
    margin: 0 16px;
    width: calc(100% - 32px);
  }
}

.button-disabled {
  user-select: none;
  pointer-events: none;
  background: #3D3D3D !important;
  opacity: 0.6;
}

a {
  color: white;
  text-decoration: none;
}