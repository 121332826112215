.bubble-container {
  position: absolute;
  z-index: 10;
}

.bubble {
  z-index: 10;
  width: 330px;
  background: black;

  @media screen and (max-width: 1152px) {
    width: 250px;
  }

  @media screen and (max-width: 1023px) {
    width: 205px;
  }

  @media screen and (max-width: 900px) {
    display: none;
  }

  &__title {
    font-size: 18px;
    font-weight: 600;
    line-height: 45px;
    letter-spacing: 0.02em;
    text-align: center;

    @media screen and (max-width: 1023px) {
      font-size: 15px;
      line-height: 36px;
    }
  }

  &__description {
    padding: 16px 16px 0 16px;
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;

    @media screen and (min-width: 1440px) {
      padding: 24px 16px 0 16px;
      font-size: 16px;
    }

    @media screen and (max-width: 1023px) {
      padding: 16px 12px 0 12px;
      font-size: 13px;
    }
  }
}