@import "src/styles/vars";

.join-input {
  position: relative;
  border-radius: 32px;
  margin-bottom: 120px;
  overflow: hidden;
  text-align: center;
  padding: 80px 20px;

  @media screen and (max-width: 1152px) {
    padding: 56px 20px;
    margin-bottom: 100px;
  }

  @media screen and (max-width: 768px) {
    padding: 80px 20px;
    margin-bottom: 80px;
  }

  @media screen and (max-width: 475px) {
    padding: 64px 24px;
    margin: 0 0 64px 0;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  img {
    position: absolute;
    z-index: -2;
    left: -10px;
    right: -10px;
    bottom: -10px;
    top: -10px;
    width: 105%;
    height: 105%;
    margin: auto;
  }

  &__title {
    font-weight: 700;
    font-size: 52px;
    line-height: 64px;
    padding-bottom: 24px;
    max-width: 400px;

    @media screen and (max-width: 1152px) {
      font-size: 40px;
      line-height: 48px;
    }
    @media screen and (max-width: 475px) {
      font-size: 30px;
      line-height: 38px;
    }
  }

  &__description {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    padding-bottom: 40px;
    max-width: 800px;
    margin: 0 auto;

    @media screen and (max-width: 1152px) {
      font-size: 18px;
      line-height: 24px;
      padding-bottom: 32px;
      max-width: 300px;
    }
    @media screen and (max-width: 475px) {
      font-size: 15px;
      line-height: 20px;
      color: $gray3;
      max-width: 210px;
    }
  }

  &__join {
    //padding-bottom: 16px;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    padding-bottom: 20px;

    @media screen and (max-width: 1152px) {
      font-size: 12px;
      line-height: 16px;
    }
  }

  &__input {
    height: 68px;
    box-sizing: border-box;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: white;
    background: #101010;
    border: 1px solid #333333;
    border-radius: 48px;
    padding: 8px 140px 8px 12px;
    width: 420px;

    @media screen and (max-width: 1152px) {
      width: 350px;
      height: 48px;
    }

    @media screen and (max-width: 475px) {
      width: 100%;
    }

    &:focus {
      outline: none;
    }

    &-container {
      display: none;
      //display: flex;
      align-items: center;
      margin: 0 auto 110px auto;
      position: relative;

      @media screen and (max-width: 1152px) {
        margin-bottom: 88px;
      }

      @media screen and (max-width: 475px) {
        margin-bottom: 120px;
      }
    }

    &-button {
      position: absolute;
      top: 4px;
      right: 4px;

      height: 60px;
      line-height: 60px;
      background: linear-gradient(134.08deg, #CEBBFE 5.95%, #A9DDEF 27.7%, #8EF8D3 49.89%, #F5EDBD 71.3%, #FBCAE2 96.85%);
      border-radius: 100px;
      width: 128px;
      box-sizing: border-box;

      font-weight: 700;
      font-size: 14px;
      color: #101010;
      padding: 0 15px;
      cursor: pointer;

      @media screen and (max-width: 1152px) {
        width: 104px;
        height: 40px;
        line-height: 40px;
        font-size: 12px;
      }

      @media screen and (max-width: 768px) {
        width: 150px;
      }

      @media screen and (max-width: 475px) {
        width: 88px;
      }

      &:hover {
        color: rgba(0,0,0, 0.7);
      }
    }
  }

  &__buttons {
    padding-top: 100px;
    padding-bottom: 0;
    display: flex;
    align-items: center;
    margin: 0 auto;

    @media screen and (max-width: 1152px) {
      padding-top: 50px;
    }

    @media screen and (max-width: 475px) {
      padding-top: 20px;
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  &__button {
    width: 185px;
    height: 48px;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;

    background: #101010;
    border: 1px solid #FFFFFF;
    border-radius: 40px;

    margin: 0 12px;
    cursor: pointer;

    transition: all 250ms;

    @media screen and (max-width: 1152px) {
      width: 150px;
      height: 40px;
      margin: 0 8px;
    }

    @media screen and (max-width: 475px) {
      margin: 8px;
    }

    span {
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      color: white;

      @media screen and (max-width: 1152px) {
        font-size: 12px;
        line-height: 16px;
      }
    }

    &:hover {
      border: 1px solid #101010;
    }
  }

  &__bubbles {
    @media screen and (max-width: 900px) {
      display: none;
    }
  }
}

#custom-substack-embed {
  width: 320px;
  height: 52px;
}