.block {
  width: 100%;
  box-sizing: border-box;
  background: #1F1F1F;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  padding: 30px 60px 30px 80px;

  @media screen and (max-width: 1152px) {
    padding: 15px 48px;
    margin-bottom: 16px;
  }

  @media screen and (max-width: 768px) {
    padding: 20px 32px;
    flex-wrap: wrap;
  }

  @media screen and (max-width: 475px) {
    padding: 12px 24px 24px 24px;
    flex-wrap: wrap;
  }

  &__title {
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
    color: white;

    @media screen and (max-width: 1152px) {
      font-size: 28px;
      line-height: 38px;
      padding-top: 8px;
    }

    @media screen and (max-width: 475px) {
      font-size: 22px;
      line-height: 30px;
    }
  }

  &__description {
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    padding-top: 24px;
    max-width: 490px;

    @media screen and (max-width: 1152px) {
      padding-top: 16px;
      font-size: 16px;
      line-height: 22px;
      max-width: 430px;
    }

    @media screen and (max-width: 768px) {
      min-width: 190px;
      padding-bottom: 5px;
    }

    @media screen and (max-width: 475px) {
      min-width: 150px;
      font-size: 14px;
      line-height: 18px;
    }
  }

  &__content {
    flex: 1;
  }

  &__image {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 250px;
    margin-right: 50px;

    @media screen and (max-width: 1180px) {
      margin-right: 40px;
    }

    @media screen and (max-width: 1080px) {
      margin-right: 30px;
    }

    @media screen and (max-width: 980px) {
      margin-right: 20px;
    }

    @media screen and (max-width: 880px) {
      margin-right: 15px;
    }

    @media screen and (max-width: 475px) {
      margin-right: 0;
    }
  }

  svg {
    width: 100%;
  }

  img {
    width: calc(100% - 150px);
    border-radius: 12px;

    @media screen and (max-width: 1080px) {
      width: calc(100% - 80px);
    }

    @media screen and (max-width: 880px) {
      width: calc(100% - 60px);
    }

    @media screen and (max-width: 475px) {
      width: calc(100% - 20px);
    }
  }

  &:nth-child(3) {
    svg {
      @media screen and (max-width: 475px) {
        height: 220px;
      }
    }
  }
}