.not-found {

  &__content {
    text-align: center;
    max-width: 650px;
    margin: 0 auto;

    .gradient-href {
      text-decoration: none !important;
      border-bottom: none !important;
    }

    &__title {
      padding-top: 20px;
      font-size: 34px;
    }

    &__description {
      font-size: 18px;
      line-height: 26px;
      padding-top: 16px;
    }
  }
}