@import "../../styles/vars";

.features {
  &__title {
    padding-bottom: 60px;

    @media screen and (max-width: 1152px) {
      padding-bottom: 40px;
    }

    @media screen and (max-width: 768px) {
      padding-bottom: 32px;
    }

    @media screen and (max-width: 475px) {
      padding-bottom: 24px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 100px;

    @media screen and (max-width: 768px) {
      margin-bottom: 80px;
    }
    @media screen and (max-width: 475px) {
      margin-bottom: 48px;
    }
  }

  &__wallet {
    margin: 20px;
    box-sizing: border-box;

    @media screen and (max-width: 475px) {
      margin: 10px;
    }
  }
}