@import "../../styles/vars";

.supported-by {
  margin-bottom: 100px;

  @media screen and (max-width: 1152px) {
    margin-bottom: 76px;
  }

  @media screen and (max-width: 475px) {
    margin-bottom: 48px;
  }

  &__title {
    padding-bottom: 32px;

    @media screen and (max-width: 1152px) {
      padding-bottom: 24px;
    }
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  &__section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 20px 50px 20px;

    &__title {
      font-size: 18px;
      font-weight: 400;
      line-height: 32px;
      text-align: center;
      color: rgb(126 126 126);
      padding-bottom: 8px;
    }

    &__content {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }
  }

  &__image {
    padding: 0 14px;

    @media screen and (max-width: 855px) {
      padding: 14px;
    }
  }
}