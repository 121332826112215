@import "src/styles/vars";

.scroller-container {
  width: 100%;
  overflow: auto;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    height: 0;
  }
}

.scroller {
  display: flex;
  align-items: center;
  padding: 0 80px;

  @media screen and (max-width: 1152px) {
    padding: 0 88px;
  }

  @media screen and (max-width: 768px) {
    padding: 0 40px;
  }

  @media screen and (max-width: 475px) {
    padding: 0 16px;
  }
}
