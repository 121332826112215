@import "../../styles/vars";

.built-by {
  margin-bottom: 100px;

  @media screen and (max-width: 1152px) {
    margin-bottom: 76px;
  }

  @media screen and (max-width: 475px) {
    margin-bottom: 48px;
  }

  &__title {
    padding-bottom: 32px;

    @media screen and (max-width: 1152px) {
      padding-bottom: 24px;
    }
  }

  &__description {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    max-width: 800px;
    margin: 0 auto 64px auto;
    text-align: center;

    @media screen and (max-width: 1152px) {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 48px;
    }

    @media screen and (max-width: 768px) {
      margin-bottom: 40px;
    }

    @media screen and (max-width: 475px) {
      font-size: 15px;
      line-height: 20px;
    }
  }

  &__gift {
    text-align: center;
    margin-top: 64px;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;

    @media screen and (max-width: 1152px) {
      margin-top: 48px;
      font-size: 20px;
      line-height: 28px;
    }

    @media screen and (max-width: 768px) {
      margin-top: 40px;
    }

    @media screen and (max-width: 475px) {
      font-size: 18px;
      line-height: 24px;
    }
  }

  &__image {
    padding-right: 24px;

    @media screen and (max-width: 1152px) {
      padding-right: 16px;
    }

    @media screen and (max-width: 768px) {
      width: 156px;
    }

    @media screen and (max-width: 475px) {
      padding-right: 8px;
    }
  }
}