@import "../../styles/vars";

.friends {
  position: relative;
  margin-bottom: 80px;

  @media screen and (max-width: 1152px) {
    margin-bottom: 100px;
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 80px;
  }

  @media screen and (max-width: 475px) {
    margin-bottom: 64px;
  }

  &__title {
    @media screen and (max-width: 475px) {
      padding-bottom: 8px;
    }
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    max-width: 1500px;
  }

  &__image {
    padding: 10px;
    transition: all 250ms;

    &:hover {
      transform: scale(1.1);
    }

    @media screen and (max-width: 475px) {
      padding: 10px;
      max-height: 60px;
    }
  }
}