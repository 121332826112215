@import "../../styles/vars";

.header {
  display: flex;
  align-items: center;
  padding: 40px 0;
  user-select: none;

  @media screen and (max-width: 1152px) {
    padding: 24px 0;
  }

  @media screen and (max-width: 475px) {
    padding: 16px 0;
  }

  &__left {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 22px;
    line-height: 32px;
    z-index: 3;
    margin-right: 14px;
    cursor: pointer;

    img {
      margin-right: 16px;
      width: 56px;

      @media screen and (max-width: 1152px) {
        margin-right: 12px;
        width: 48px;
      }
      @media screen and (max-width: 475px) {
        width: 40px;
      }
    }

    @media screen and (max-width: 1152px) {
      font-size: 18px;
    }
    @media screen and (max-width: 475px) {
      font-size: 16px;
    }
  }

  &__right {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    padding-right: 180px;

    @media screen and (max-width: 1152px) {
      padding-right: 150px;
    }

    @media screen and (max-width: 825px) {
      padding-right: 90px;
    }

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  &__link {
    padding: 5px;
    margin-right: 14px;
    color: white;
    text-decoration: none;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    min-width: 40px;

    @media screen and (max-width: 1152px) {
      font-size: 14px;
      line-height: 20px;
    }
  }

  &__burger {
    display: none;
    padding: 6px;
    cursor: pointer;
    z-index: 3;

    @media screen and (max-width: 768px) {
      display: block;
      position: absolute;
      top: 30px;
      right: 34px;
    }

    @media screen and (max-width: 475px) {
      top: 16px;
      right: 16px;
    }
  }

  &__address-button {
    height: 40px;
    padding: 8px 10px 8px 8px;

    @media screen and (max-width: 768px) {
      padding: 10px 12px 10px 10px;
    }

    &__title {
      padding: 0 8px;
    }
  }

  &__tools {
    position: relative;

    @media screen and (max-width: 768px) {
      .button-gray {
        font-size: 20px;
      }

      .header-dropdown__line__text {
        font-size: 16px;
      }
    }
  }
}

.menu-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2;

  &__content {
    background: #101010;
    z-index: 3;
    padding: 100px 40px 40px 40px;
    position: relative;

    &__link {
      font-weight: 500;
      font-size: 30px;
      line-height: 38px;
      color: #828282;
      margin-bottom: 24px;
      cursor: pointer;

      &:last-child {
        margin-bottom: 0;
      }

      &--disabled {
        color: rgba(130, 130, 130, 0.6);
      }
    }
  }

  &__fade {
    background: #101010;
    opacity: 0.5;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 2;
  }
}
